@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@400;500;600;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Bitter', 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif;
  background: #2e7d32;
  color: #f8faf2; 
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.game-info, .next-game {
  text-align: left;
  font-size: 2.5em;
  box-sizing: border-box;
  font-weight: 700;
  letter-spacing: -0.02em;
  line-height: 1.2; 
  display: block;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  max-width: 640px;
  padding: 0 20px;
  font-family: 'Bitter', serif;
}

.game-info p, .next-game p {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-info {
  margin-bottom: 20px;
}

div.follow-up {
  margin-top: 1em;
  font-size: 0.8em;
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 15px;
  border-radius: 8px;
  max-width: 100%;
}

.link {
  color: #f8faf2;
  text-decoration: underline;
  font-weight: 600;
  transition: all 0.2s ease;
  margin: 0 8px;
}

.link:hover {
  color: #CC3433;
}

.link:visited {
  color: #f8faf2;
}

.emoji-corner {
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: 1em;
  padding: 15px;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
  background: rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.separator {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 4px;
}

a:visited {
  color: #f8faf2;
}

.loading, .error {
  font-size: 1.5em;
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error {
  color: #f8d7da;
  background-color: rgba(220, 53, 69, 0.2);
}

.schedule-title {
  text-align: center;
  font-size: 3.2em;
  font-weight: 700;
  margin: 40px auto 32px;
  padding: 0;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  letter-spacing: -0.01em;
  width: 100%;
  max-width: 600px;
  font-family: 'Bitter', serif;
}

.next-7-days {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  gap: 16px;
  padding: 0 20px;
  box-sizing: border-box;
}

.game-cell {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 120px 1fr auto;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(0, 0, 0, 0.04);
}

.game-cell:hover {
  transform: translateY(-2px) scale(1.01);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.game-date {
  font-size: 1em;
  font-weight: 700;
  background: #f8f9fa;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  line-height: 1.3;
  gap: 4px;
  font-family: 'Source Sans Pro', sans-serif;
}

.game-date span:first-child {
  font-size: 0.85em;
  font-weight: 600;
  color: #2e7d32;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.game-date span:last-child {
  font-size: 1.6em;
  color: #1a1a1a;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.game-opponent {
  font-size: 1.5em;
  font-weight: 600;
  color: #1a1a1a;
  padding: 0 32px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0;
  font-family: 'Bitter', serif;
}

.game-time {
  font-size: 1.15em;
  font-weight: 500;
  color: #2e7d32;
  padding: 0 32px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: #f8f9fa;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  letter-spacing: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

.game-status {
  font-size: 1.15em;
  font-weight: 400;
  color: #666;
  padding: 0 32px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

/* Home and away game styling */
.home {
  background: linear-gradient(to right, #ffffff, #fcfcfc);
}

.home::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background: #2e7d32;
}

.away {
  background: linear-gradient(to right, #ffffff, #fcfcfc);
}

.away::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background: #43a047;
}

.off {
  background: linear-gradient(to right, #ffffff, #fcfcfc);
}

.off::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 6px;
  background: #9e9e9e;
}

/* Sun emoji */
.game-cell.day-game::before {
  display: none;
}

@media (max-width: 768px) {
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
  }

  #root {
    padding: 40px 0 80px 0;
    align-items: flex-start;
    width: 100vw;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
  }

  .schedule-title {
    font-size: 2.4em;
    margin: 32px auto 24px;
  }

  .next-7-days {
    width: calc(100vw - 32px);
    max-width: calc(100vw - 32px);
    padding: 0 16px;
    gap: 12px;
  }

  .game-cell {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto auto;
    border-radius: 16px;
  }

  .game-date {
    grid-row: 1 / 3;
    padding: 20px 16px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }

  .game-date span:first-child {
    font-size: 0.8em;
  }

  .game-date span:last-child {
    font-size: 1.4em;
  }

  .game-opponent {
    grid-column: 2;
    grid-row: 1;
    padding: 20px 24px 6px 24px;
    font-size: 1.3em;
    align-items: flex-end;
  }

  .game-time {
    grid-column: 2;
    grid-row: 2;
    padding: 6px 24px 20px 24px;
    font-size: 1.1em;
    background: transparent;
    border: none;
    align-items: flex-start;
  }

  .game-status {
    grid-column: 2;
    grid-row: 1 / 3;
    padding: 20px 24px;
    font-size: 1.1em;
    align-items: center;
  }

  .game-info, .next-game {
    font-size: 2em;
    padding: 0 20px;
    line-height: 1.1;
    margin-top: 0;
    max-width: calc(100vw - 40px);
    width: 100%;
  }

  .game-info p, .next-game p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }

  .main-info {
    margin-bottom: 16px;
    text-align: left;
  }

  .main-info p {
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  div.follow-up {
    margin-top: 0.8em;
    font-size: 0.8em;
    opacity: 0.9;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 12px;
    border-radius: 8px;
    text-align: left;
  }

  div.follow-up p {
    margin-bottom: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .emoji-corner {
    padding: 12px;
    font-size: 0.9em;
  }
}

/* Even smaller screens */
@media (max-width: 380px) {
  .schedule-title {
    font-size: 2.2em;
    margin: 24px auto 20px;
  }

  .next-7-days {
    width: calc(100vw - 24px);
    max-width: calc(100vw - 24px);
    padding: 0 12px;
    gap: 10px;
  }

  .game-cell {
    border-radius: 14px;
  }

  .game-date {
    padding: 16px 12px;
  }

  .game-date span:first-child {
    font-size: 0.75em;
  }

  .game-date span:last-child {
    font-size: 1.3em;
  }

  .game-opponent {
    padding: 16px 20px 4px 20px;
    font-size: 1.2em;
  }

  .game-time {
    padding: 4px 20px 16px 20px;
    font-size: 1em;
  }

  .game-status {
    padding: 16px 20px;
    font-size: 1em;
  }

  .game-info, .next-game {
    font-size: 1.8em;
    padding: 0 16px;
    max-width: calc(100vw - 32px);
  }

  .game-info p, .next-game p {
    margin-bottom: 6px;
  }

  .main-info {
    margin-bottom: 12px;
  }

  div.follow-up {
    font-size: 0.65em;
    padding: 10px;
    line-height: 1.2;
  }

  div.follow-up p {
    margin-bottom: 3px;
  }

  #root {
    padding: 32px 0 64px 0;
  }

  .emoji-corner {
    padding: 10px;
    font-size: 0.85em;
  }
}

/* Remove all day game and sun emoji styling */
.day-game {
  position: relative;
}

/* Home and away game styling */
.home .game-opponent,
.home .game-time,
.away .game-opponent,
.away .game-time,
.home .game-date,
.away .game-date {
  color: #000000;
}

@keyframes highlightText {
  0% {
    background-color: transparent;
    color: #f8faf2;
  }
  20% {
    background-color: #f8faf2;
    color: #2e7d32;
  }
  40% {
    background-color: transparent;
    color: #f8faf2;
  }
  60% {
    background-color: #f8faf2;
    color: #2e7d32;
  }
  80% {
    background-color: transparent;
    color: #f8faf2;
  }
  100% {
    background-color: transparent;
    color: #f8faf2;
  }
}

.day-game-highlight {
  animation: highlightText 3s ease-in-out;
  border-radius: 4px;
  display: inline-block;
}